<template>
  <div class="bg-white p-4 h-full text-black">
    <h2 class="font-bold fs-24 mb-4">{{ $t("Thiết lập Dịch vụ") }}</h2>
    <el-form :model="form" :rules="rules" ref="createServiceLocal">
      <div class="flex gap-2 flex-col md:flex-row items-center">
        <el-form-item class="flex-1" prop="local_name">
          <label class="required">{{ $t("Dịch vụ") }}</label>
          <el-select
            class="w-full"
            clearable
            :disabled="$route.params.id ? true : false"
            v-model="form.local_name"
            placeholder="Chọn dịch vụ"
            filterable
            @change="handleSelectedServiceCatalog"
            popper-class="popper-select-service"
          >
            <el-option
              v-for="(item, index) in serviceCatalogs"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class>
        <!-- <el-form-item class="flex-1" prop="code">
          <label class>{{ $t("Mã dịch vụ") }}</label>
          <el-input class v-model="form.code"></el-input>
        </el-form-item>-->
        <!-- <el-form-item class="flex-1" prop="local_price">
          <label class="required block">{{ $t("Giá dịch vụ") }}</label>
          <el-input
            class="cs-input cs-price"
            v-model="form.local_price"
            @input="handleChangePrice"
          >
            <template slot="append">VND</template>
          </el-input>
        </el-form-item>-->
        <div class="flex gap-2 flex-col md:flex-row items-center"></div>
        <div class="flex flex-col md:flex-row gap-4 items-center">
          <el-form-item class="flex-1" prop="code">
            <label class>{{ $t("Mã dịch vụ") }}</label>
            <el-input disabled class v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item class="flex-1" prop="price">
            <label class="required block">{{ $t("Giá dịch vụ") }}</label>
            <el-input
              class="cs-input cs-price"
              v-model="form.local_price"
              @input="handleChangePrice"
            >
              <template slot="append">VND</template>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item prop="description">
          <label class>{{ $t("Mô tả") }}</label>
          <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.local_description"></el-input>
        </el-form-item>
        <div class="flex gap-4 flex-col md:flex-row items-center">
          <!-- <el-form-item class="flex-1" prop="type">
            <el-checkbox
              disabled
              v-model="form.is_internal_service"
              label="Đây là dịch vụ do tổ chức tự cung cấp"
              name="type"
            ></el-checkbox>
          </el-form-item>-->
          <el-form-item class="flex-1" prop="type">
            <el-checkbox
              disabled
              v-model="form.is_accept_outbound_request"
              label="Cho phép các tổ chức bên ngoài đặt dịch vụ"
              name="type"
            ></el-checkbox>
          </el-form-item>
        </div>
        <div class="flex flex-col md:flex-row gap-4 items-center">
          <!-- <el-form-item class="flex-1" prop="group_cate">
          <label class="block">{{ $t("Nhóm Danh mục Dịch vụ") }}</label>

          <el-select
            class="w-full"
            clearable
            v-model="form.group_cate"
            placeholder="Chọn nhóm danh mục dịch vụ"
          >
            <el-option
              v-for="(groupItem, index) in groupCates"
              :key="index"
              :label="groupItem.name"
              :value="groupItem.id"
            ></el-option>
          </el-select>
          </el-form-item>-->
          <el-form-item class="flex-1" prop="services_category_id">
            <label class="block">{{ $t("Danh mục Dịch vụ") }}</label>
            <el-input disabled class v-model="form.services_category_name"></el-input>

            <!-- <el-select
              class="w-full"
              clearable
              v-model="form.services_category_id"
              placeholder="Chọn danh mục dịch vụ"
            >
              <el-option
                v-for="(serviceCateItem, index) in serviceCates"
                :key="index"
                :label="serviceCateItem.name"
                :value="serviceCateItem.id"
              ></el-option>
            </el-select>-->
          </el-form-item>
        </div>
        <div class="flex flex-col md:flex-row gap-4 items-center">
          <el-form-item class="flex-1" prop="provider">
            <label class="block">{{ $t("Đơn vị cung cấp") }}</label>
            <el-input disabled class v-model="form.services_provider_name"></el-input>

            <!-- <el-select
              class="w-full"
              clearable
              v-model="form.service_provider_id"
              placeholder="Chọn đơn vị cung cấp"
            >
              <el-option
                v-for="(provider, index) in serviceProviders"
                :key="index"
                :label="provider.name"
                :value="provider.id"
              ></el-option>
            </el-select>-->
          </el-form-item>
          <el-form-item class="flex-1" prop="service_type">
            <label class="block">{{ $t("Loại Dịch vụ") }}</label>

            <el-select
              disabled
              class="w-full"
              clearable
              v-model="form.service_type"
              placeholder="Chọn loại dịch vụ"
            >
              <el-option
                v-for="(typeItem, index) in SERVICE_TYPE"
                :key="index"
                :label="typeItem.label"
                :value="typeItem.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="flex flex-col md:flex-row gap-4 items-center">
          <el-form-item class="flex-1" prop="decision_no">
            <label class>{{ $t("Số quyết định") }}</label>
            <el-input disabled v-model="form.decision_no"></el-input>
          </el-form-item>
          <el-form-item class="flex-1" prop="publish_date">
            <label class>{{ $t("Ngày quyết định") }}</label>
            <el-date-picker disabled v-model="form.publish_date" type="date"></el-date-picker>
          </el-form-item>
        </div>
        <h5 v-if="listElement && listElement.length" class="mb-2 fs-18 font-bold">Chỉ số</h5>
        <div class="element mb-3" v-if="listElement && listElement.length">
          <el-collapse v-model="activeElement" accordion>
            <el-collapse-item
              :title="`Chỉ số ${index + 1}`"
              :name="item.index"
              v-for="(item, index) in listElement"
              :key="index"
            >
              <div class="grid grid-cols-3 gap-3">
                <el-form-item class="flex-1">
                  <label class>{{ $t("Mã chỉ số") }}</label>
                  <el-input disabled v-model="item.service_element_code"></el-input>
                </el-form-item>
                <el-form-item class="flex-1">
                  <label class>{{ $t("Tên chỉ số") }}</label>
                  <el-input disabled v-model="item.name"></el-input>
                </el-form-item>
                <el-form-item class="flex-1">
                  <label class>{{ $t("Giá") }}</label>
                  <el-input disabled v-model="item.price">
                    <template slot="append">VND</template>
                  </el-input>
                </el-form-item>
              </div>

              <div>
                <p class="mb-0 text-base">Khoảng tham chiếu</p>
                <div class="grid grid-cols-3 gap-3">
                  <el-form-item>
                    <label class>{{ $t("Dưới") }}</label>
                    <el-input disabled v-model="item.min_value"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1">
                    <label class>{{ $t("Trên") }}</label>
                    <el-input disabled v-model="item.max_value"></el-input>
                  </el-form-item>
                  <el-form-item class="flex-1">
                    <label class>{{ $t("Đơn vị") }}</label>
                    <el-input disabled v-model="item.element_unit"></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-form-item class="flex-1">
                <label class>{{ $t("Mô tả") }}</label>
                <el-input disabled rows="3" type="textarea" v-model="item.description"></el-input>
              </el-form-item>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <!-- <el-form-item prop="desc">
        <label class>{{ $t("Mô tả") }}</label>
        <el-input type="textarea" :autosize="{ minRows: 4 }" v-model="form.local_description"></el-input>
      </el-form-item>-->

      <el-form-item class="flex justify-end">
        <el-button @click="resetForm()">{{ $t("Huỷ") }}</el-button>
        <el-button
          v-if="$route.params.id"
          type="primary"
          @click="submitForm()"
          :loading="isLoading"
        >{{ $t("Cập nhật") }}</el-button>
        <el-button v-else type="primary" @click="submitForm()">{{ $t("Tạo mới") }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import { SERVICE_TYPE, SERVICE_GROUP } from '../../utils/constants'

export default {
  name: 'CreateServiceLocal',
  data () {
    return {
      isLoading: false,
      form: {
        local_name: '',
        local_price: '',
        local_description: ''
      },
      priceFormat: '',
      rules: {
        local_name: [
          {
            required: true,
            message: 'Vui lòng chọn dịch vụ',
            trigger: 'blur'
          }
        ],
        local_price: [
          {
            required: true,
            message: 'Vui lòng nhập giá dịch vụ',
            trigger: 'blur'
          }
        ]
      },
      groupCates: [],
      serviceCatalogs: [],
      providers: [],
      serviceTypes: [],
      serviceDetail: {},
      activeElement: 0,
      listElement: [
        {
          name: '',
          service_element_code: '',
          description: '',
          price: '',
          min_value: '',
          max_value: '',
          index: 0,
          element_unit: ''
        }
      ],
      SERVICE_TYPE,
      SERVICE_GROUP,
      serviceCategories: []
    }
  },
  created () {
    this.getListServiceCatalog()
    this.getListServiceCategory()
    this.getListServiceProvider()
  },
  async mounted () {
    if (this.$route.params.id) {
      await this.getDetailServiceById()
      const selectedInfo = this.serviceDetail.service_catalog || {}
      this.form = {
        local_name: this.serviceDetail.service_catalog_id,
        local_description: this.serviceDetail.local_description,

        code: this.serviceDetail?.service_catalog?.services_code,
        services_provider_name: this.providers?.find(
          elm => elm.id === selectedInfo.service_provider_id
        )?.name,
        services_category_name: this.serviceCategories?.find(
          elm => elm.id === selectedInfo.services_category_id
        )?.name,
        ...this.serviceDetail,
        ...selectedInfo
      }
      this.form.local_price = appUtils.numberFormat(
        Number(this.serviceDetail.local_price?.toString()?.replaceAll(',', ''))
      )
      this.listElement = this.serviceDetail?.service_catalog?.service_elements?.map(
        elm => {
          return {
            ...elm,
            element_unit: elm.element_unit,
            max_value: elm.max_value || '',
            price: elm.price == 0 ? '' : appUtils.numberFormat(elm.price),
            min_value: elm.min_value || '',
            index: this.serviceDetail?.service_catalog?.service_elements
              ?.map(item => item.id)
              .indexOf(elm.id)
          }
        }
      )
    }
  },
  methods: {
    async getListServiceCategory () {
      if (!this.$globalClinic?.org_id) return
      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceCategory(params)

        const responseData = response.data || {}
        this.serviceCategories = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    async getListServiceProvider () {
      if (!this.$globalClinic?.org_id) return
      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getListServiceProvider(params)
        const responseData = response.data || {}
        this.providers = responseData?.data || []
      } catch (error) {
        console.log(error)
      }
    },
    submitForm () {
      this.$refs.createServiceLocal.validate(valid => {
        if (valid) {
          if (!this.$route.params.id) this.createServiceLocal()
          else this.updateServiceLocal()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.createServiceLocal.resetFields()
      this.$router.go(-1)
    },

    handleChangePrice (value) {
      const newValue = Number(value?.toString()?.replaceAll(',', ''))
      this.priceFormat = appUtils.numberFormat(newValue)
      this.form.local_price = this.priceFormat
    },
    async getListServiceCatalog () {
      if (!this.$globalClinic?.org_id) return
      try {
        const params = {
          org_id: this.$globalClinic?.org_id,
          page_num: 1,
          page_size: 1000,
          sort_by: 'created_at',
          order: 'desc'
        }
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .getIndicationService(params)
        const responseData = response.data || {}
        this.serviceCatalogs = responseData?.data || []
        console.log(response)
      } catch (error) {
        console.log(error)
      }
    },
    async createServiceLocal () {
      try {
        this.isLoading = true
        const params = {
          local_name: this.serviceCatalogs?.find(
            elm => elm.id === this.form.local_name
          )?.name,
          local_price: Number(
            this.form.local_price?.toString()?.replaceAll(',', '')
          ),
          local_description: this.form.local_description,
          workspace_id: this.$globalClinic?.id,
          org_id: this.$$globalOrg?.id,
          service_catalog_id: this.form.local_name,
          status: 1
        }
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .createServiceLocal(params)
        if (result.status === 200) {
          this.$router.push({ name: 'ListService' })
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async updateServiceLocal () {
      try {
        this.isLoading = true
        const params = {
          local_name: this.form.local_name,
          local_price: Number(
            this.form.local_price?.toString()?.replaceAll(',', '')
          ),
          local_description: this.form.local_description,
          workspace_id: this.$globalClinic?.id,
          org_id: this.$$globalOrg?.id,
          service_catalog_id: this.form.service_catalog_id,
          status: 1
        }
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .updateServiceLocal(this.$route.params.id, params)
        if (result.status === 200) {
          this.$router.push({ name: 'ListService' })
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    async getDetailServiceById () {
      try {
        const result = await this.$rf
          .getRequest('DoctorRequest')
          .getDetailServiceById(this.$route.params?.id)
        if (result.status === 200) {
          this.serviceDetail = result.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    formatter (price) {
      return `${appUtils.numberFormat(price)}`
    },
    handleSelectedServiceCatalog (id) {
      const selectedInfo =
        this.serviceCatalogs?.find(item => item?.id === id) || {}
      this.form = {
        ...selectedInfo,
        local_name: selectedInfo.id,
        local_price: appUtils.numberFormat(selectedInfo.price),
        is_internal_service: selectedInfo.is_internal_service === 2,
        is_accept_outbound_request:
          selectedInfo.is_accept_outbound_request === 2,
        code: selectedInfo.services_code,
        services_category_name: selectedInfo.service_category?.name || '',
        services_provider_name: selectedInfo.service_provider?.name || ''
      }
      this.listElement =
        selectedInfo?.service_elements.map(elm => {
          return {
            ...elm,
            element_unit: elm.element_unit,
            max_value: elm.max_value || '',
            price: elm.price ? appUtils.numberFormat(elm.price) : '',
            min_value: elm.min_value || '',
            index: selectedInfo?.service_elements
              ?.map(item => item.id)
              .indexOf(elm.id)
          }
        }) || []
      if (this.listElement.length) this.activeElement = 0
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-input {
    .el-input__inner {
      text-align: right;
      border: 1px solid #dcdfe6 !important;
    }
  }
}

.cs-price {
  .el-input__inner {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

::v-deep.element {
  border-radius: 8px;
  padding: 12px;
  background-color: #f6f6f6;
  .el-collapse .el-collapse-item {
    .el-collapse-item__header {
      background-color: #f6f6f6;
    }
    .el-collapse-item__wrap {
      transition: all ease 0.3s;
      background-color: #f6f6f6;
      .el-collapse-item__content {
        padding-bottom: 0;

        .el-form-item__content {
          .el-input .el-input__inner {
            border-radius: 4px !important;
            border: 1px solid #dbdbdb !important;
          }
        }
      }
    }
  }
}

::v-deep.el-button {
  &:focus {
    outline: 1px auto;
    // margin-left: 1px;
    // border: unset;
  }
}
::v-deep.btn-delete {
  height: 38px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss">
.popper-select-service {
  .el-scrollbar
    .el-scrollbar__wrap
    .el-select-dropdown__list
    .el-select-dropdown__item
    span {
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    max-width: 900px;
    display: block;
  }
}
</style>
